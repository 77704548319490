<template>
  <v-container id="crud" fluid tag="section" class="px-6">
    <v-toolbar flat color="white" class="pr-4">
      <v-row>
        <v-col sm="4" class="pt-5 pl-2 small">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="'Búsqueda de Rol / Grupos'"
            single-line
            hide-details
            dense
            rounded
            solo
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row class="mt-6">
        <v-col cols="12"  sm="9" class="py-0 font-weight-medium" style="font-size: 1.4rem;">  {{ title }} </v-col>

        <v-col cols="12"  sm="3"   class="my-0 py-0 mr-0 text-end">
         <!--<v-btn
            class="text-center primary"
            elevation="0"

            @click="toggleActivarTodos()"
          >
            <span class="py-0 px-0" style="font-size: 0.7rem;padding: 5px;">Activar / Desactivar<br>Todos los Grupos</span>
          </v-btn>
        -->


           <v-switch

                  v-model="activarDesactivarTodos"

                  @click="toggleActivarTodos(activarDesactivarTodos)"
                  color="primary"
                  hide-details
                  class="pt-0 mt-0"
                  :label="parseInt(activarDesactivarTodos) ==1 ? 'Todos activos':'Todos inactivos'"
                  false-value=0
                  true-value=1 >
              </v-switch>
        </v-col>

    </v-row>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredData"
        sort-by="id"
        :search="search"
        sort-desc
        class="elevation-1 custom-striped-table"
        style="width:100%;"
      >



        <template v-slot:[`item.nombre`]="{ item }">
          <td style="width: 10%; vertical-align: middle;">
            <div class="primary rounded-pill text-center mr-0 " style="color:#fff;font-size:0.9rem;width: 160px;padding:3px 1px; " >
              {{ item.nombre }}
            </div>


          </td>
        </template>

        <template v-slot:[`item.mensaje`]="{ item }">

          {{ item.mensaje.length >75 ? item.mensaje.substring(0,75)+"...": item.mensaje }}

        </template>

        <template v-slot:[`item.last_modified_by_user`]="{ item }">
          <td style="width: 200px; text-align: left; vertical-align: middle;">
            {{ item.last_modified_by_user }}<br>{{ item.updated_at}}
          </td>
        </template>

        <template v-slot:[`item.activo`]="{ item }">
          <div
						:class="$getCss(item.activo, 'activo')"
						style="width: 25px"
						dark
					>
					&nbsp;</div>
        </template>
        <template v-slot:[`item.aviso_estado`]="{ item }">
           <td style="width: 40px;" >
          <div
						:class="$getCss(item.aviso_estado, 'aviso_estado')"
						style="width: 25px"
						dark
					>
					&nbsp;</div></td>
        </template>




        <!--<template v-slot:[`item.activo`]="{ item }">
          <td style="width: 100px;">

            <v-switch

            v-model="item.activo"

            @click="toggleActivo(item)"


            color="primary"
            hide-details
            class="pt-0 mt-0"


            false-value=0
            true-value=1

          >
          </v-switch>
          </td>


				</template>-->
        	<template v-slot:[`item.actions`]="{ item }">
            <td style="width: 40px;" class="mx-0 px-0">
              <v-switch

                  v-model="item.activo"

                  @click="toggleActivo(item)"
                  color="primary"
                  hide-details
                  class="pt-0 mt-0"
                  title="Activar o desactivar Grupo"
                  false-value=0
                  true-value=1 >
              </v-switch>
            </td>
           <td style="width: 40px;" class="mx-0 px-0">
              <v-icon
                small
                style="font-size: 20px;"
                :color="$cv('btnEditar')"
                title="Editar Mensaje de bloqueo"
                @click="
                  $editItem(item.id);
                "
              >
                mdi-pencil
              </v-icon>
           </td>
            <td style="width: 40px;" class="mx-0 px-0">
          <v-icon
						small
            style="font-size: 20px;"
						:color="$cv('btnEditar')"
						title="Aviso emergente"
						@click="
							editarAviso(item);
						"
					>
						mdi-email
					</v-icon>
          </td>
				</template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
    <!-----------mensaje bloqueo---------->
    <v-dialog
      v-model="dialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              Editar Rol / Grupo: {{ editedItem.nombre }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
              dark
              text

              @click="$save()"
            >
              Guardar
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <!--<v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Rol"
                    v-model="editedItem.nombre"
                    disabled
                    :rules="[
                      $rulesRequerido,
                      $rulesAlfaNum,
                      $rulesMax500,
                    ]"
                  >
                  </v-text-field>
                </v-col>-->

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    label="Mensaje"
                    v-model="editedItem.mensaje"

                    :rules="[
                      $rulesRequerido,
                      $rulesMax500,
                    ]"
                  >
                  </v-textarea>
                </v-col>



              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <!-----------mensaje bloqueo---------->
    <!-----------aviso emergente---------->

     <v-dialog
      v-model="dialogs.aviso"
      max-width="50%"
      persistent
    >
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark :color="colorPrioridad">

            <v-toolbar-title class="ml-6">
              Editar aviso emergente {{ editedItem.nombre }}
            </v-toolbar-title>
            <v-spacer></v-spacer>


          </v-toolbar>

          <v-card-text>

              <v-row  class="mx-0 px-0 py-0  my-0">

                <v-col cols="12" sm="8"  class="my-0 py-0">
                  <label class="px-0 mx-0 py-0 my-0"
                         :class="{ /*'color-red': errors['nombreApellido']*/ }"

                  >Prioridad</label>
                </v-col>
                <v-col cols="12" sm="3"   class="my-0 py-0">
                  <label class="px-0 mx-0 py-0 my-0 ml-2"
                         :class="{ /*'color-red': errors['nombreApellido']*/ }"

                  >Estado</label>
                </v-col>



                <v-col  cols="12" sm="8" class="mx-0 px-0 my-0 py-0">
                  <v-select
                    dense
                    outlined
                    :items="[
                      { text: 'Baja', value: 1 },
                      { text: 'Media', value: 2 },
                      { text: 'Alta', value: 3 },
                    ]"
                    v-model="editedItem.aviso_prioridad"
                    class="mx-3"
                    @change="elegirPrioridad"
                     :rules="[$rulesRequerido]"

                  >
                </v-select>
                </v-col>
                <v-col  sm="3" class="mx-0 px-0 ml-6 mt-2 my-0 py-0">
                   <v-switch
                      dense
                      outlined
                      v-model="editedItem.aviso_estado"
                      :label="editedItem.aviso_estado ==='1'? 'Activo': 'Inactivo'"
                      color="primary"
                      hide-details
                      class="pt-0 mt-0"
                      false-value=0
                      true-value=1
                    >
                  </v-switch>
                </v-col>

              </v-row>
              <v-row  class="mx-0 px-0 py-0  my-0">
                <v-col cols="12" sm="12" class="my-0 py-0">
                  <label class="px-0 mx-0 py-0 my-0"
                         :class="{ /*'color-red': errors['nombreApellido']*/ }"

                  >Titulo</label>
                </v-col>
                 <v-col cols="12" sm="12"   class="my-0 py-0">
                  <v-text-field

                    v-model="editedItem.aviso_titulo"
                    dense
                    outlined
                    :rules="[
                      $rulesRequerido,
                      $rulesAlfaNum,
                      $rulesMax500,
                    ]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row  class="mx-0 px-0  py-0  my-0">

                <v-col cols="12" sm="8" md="8" class="my-0 py-0">
                  <label class="px-0 mx-0 py-0 my-0"
                         :class="{ /*'color-red': errors['nombreApellido']*/ }"

                  >Contenido del aviso</label>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="mx-0 px-2 pr-3 my-0 py-0">
                  <v-textarea

                    v-model="editedItem.aviso_mensaje"
                    dense
                    outlined
                    :rules="[
                      $rulesRequerido,
                      $rulesMax500,
                    ]"
                  >
                  </v-textarea>
                </v-col>
              </v-row>


          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>


          <v-btn  color="white" @click="dialogs.aviso=false" class="capitalize-first custom-btn">
          Cancelar
        </v-btn>
        <v-btn  :color="colorPrioridad" dark @click="saveAviso()"  class="capitalize-first custom-btn">

         Guardar
        </v-btn>

        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-----------aviso emergente---------->

    <dialog-form-completed
                v-model="dialogs['avisoPublicado']"

                title="Acción completada"
                info="El aviso se ha publicado correctamente"
                icon="mdi-check"
                color="#0078D433"
                @confirm="dialogs['avisoPublicado']=false"
          />

  </v-container>
</template>

<script>
import Swal from "sweetalert2";

function title() {
  return "Roles / Grupos";
}

import DialogFormCompleted from "@/views/dialogs/FormCompleted.vue";


export default {
  components: {

    DialogFormCompleted
  },
  data() {

    return {
      valid: true,

      editedIndex: -1,


      editedItem: {
        nombre: "",
        mensaje: "",
        activo: 1,
      },
      defaultItem: {
        nombre: "",
        mensaje: "",
        activo: 1,
      },


      snackbar: false,
      text: "Registro Insertado",
      color: "success",
      timeout: 4000,
      search: "",
      title: title(),
      route: "gestionGrupos",

      headers: [
        { text: "Rol / Grupo", value: "nombre" },
        { text: "Mensaje", value: "mensaje" },




        { text: "Modificado", value: "last_modified_by_user", sortable: false },
        { text: "Grupo Estado", value: "activo", sortable: false },


        { text: "Aviso Estado", value: "aviso_estado" },
        { text: "", value: "actions", sortable: false },
      ],
      desserts: [],
      filters: {},
      selectedHeaders: [],

      //form : [],
      colorPrioridad:"primary",
      dialog:false,
      dialogs: {

      aviso: false,
      avisoPublicado: false,
      activarDesactivarTodos:false

    },

    };
  },
  computed: {
    formTitle() {
      return  "Editar " + this.title;
    },
    filteredData() {

      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$close();
      } else {
        if (this.editedItem.id) {
          this.editedIndex = this.editedItem.id;
        } else {
          this.editedIndex = -1;
        }
      }
    },
  },
  created() {
    this.$initialize(true);

    this.selectedHeaders = this.headers;
  },
  methods: {


      async saveAviso() {
            let vm = this;

            if (vm.$refs.form.validate()) {

                  Swal.alertGetInfo("Actualizando información");

                  await vm.$axiosApi.put(vm.route, vm.editedIndex, vm.editedItem)
                    .then((r) => {
                        vm.$respuesta(vm, r, 2);

                        vm.dialogs.aviso =false;
                        if(vm.editedItem.aviso_estado == 1)
                          vm.dialogs.avisoPublicado =true;

                    }).catch((e) => {
                        vm.$respuesta(vm, e, 2, true);

                    });



                Swal.close();

            }
        },

      elegirPrioridad()
      {
        switch (parseInt(this.editedItem.aviso_prioridad))
        {
          case 1:
            this.colorPrioridad ="primary";
          break;
          case 2:
            this.colorPrioridad ="success";
          break;
          case 3:
            this.colorPrioridad ="danger";
          break;
        }
      },
     async editarAviso(item) {

        this.editedIndex = item.id;

        this.editedItem = Object.assign({}, item);

        this.editedItem.aviso_prioridad = parseInt(this.editedItem.aviso_prioridad);

        this.dialogs.aviso = true;

        this.elegirPrioridad();
    },

     async toggleActivarTodos(activarDesactivarTodos) {

       Swal.alertGetInfo("Actualizando información");

          try {

            const response = await this.$axiosApi  .put(this.route+'_enable_all', activarDesactivarTodos)

            if (response.data.code == 200) {

              //item.activo = val ? 1 : 0;

              this.snackbar = true;
              this.text = "Se modificaron los estados con exito.";
              this.color = "success";
            } else {

                this.snackbar = true;
                this.text = "Error al cambiar el los estados";
                this.color = "error";
            }
            this.$initialize();
          } catch (error) {

            console.error("Error al cambiar los estados:", error);
            this.snackbar = true;
                this.text = "Error al cambiar los estados:"+error;
                this.color = "error";
            this.$initialize();
          }
          finally {
            Swal.close();
          }
    },

    async toggleActivo(item) {
        Swal.alertGetInfo("Actualizando información");
        const originalState = item.activo; // Guarda el estado original


        try {

          const response = await this.$axiosApi  .put(this.route+'_enable', item.id)



          if (response.data.code == 200) {

            this.snackbar = true;
            this.text = "Se modificó el estado con exito.";
            this.color = "success";
          } else {
            item.activo = originalState;
              this.snackbar = true;
              this.text = "Error al cambiar el estado";
              this.color = "error";
          }
          this.$initialize();
        } catch (error) {
          item.activo = originalState;
          console.error("Error al cambiar el estado:", error);
          this.snackbar = true;
              this.text = "Error al cambiar el estado:"+error;
              this.color = "error";
          this.$initialize();
        }
        finally {
                Swal.close();

        }
    },







  },
  mounted() {
    console.log("Componente " + this.route + " creado");
  },
};
</script>

<style scoped>
.capitalize-first {
  text-transform: lowercase; /* Convierte todo el texto a minúsculas */
}

.capitalize-first::first-letter {
  text-transform: uppercase; /* Capitaliza solo la primera letra */
}

</style>
